// ==========================================================================
// Website or App Stylesheet
// ==========================================================================

// Import Base
@import "base";
@import "lesshat/lesshat";
@import "fontawesome/font-awesome";

// Login
@import "styles/_cmm_login";
@import "styles/_dashboard";
// Website / App Styles
@import "styles/_variables-colors";
@import "styles/_header";
@import "styles/_footer";
@import "styles/_wgt";
@import "styles/_wgt_all";
@import "styles/_wgt__info-user";
@import "styles/_wgt_toolbar_bottom_fixed";
@import "styles/wgt__toolbar-top";
@import "styles/_wgt_left_info";
@import "styles/_wgt_left_tabs";
@import "styles/_wgt_percents_chart";
@import "styles/_wgt_dragger_map";
@import "styles/_alert";


// Import Base Helpers
@import "base/_helpers";

//Vendor
@import "styles/coolautosugest";


/*APP Overrides an Unique Proyect Properties*/
section{
	>pre{
		position: relative;
		&:before{
			left: 10px;
			top: 0px;
			content: "Code Snippet";
			color:#DE6B48;
			font-weight: bolder;
			font-style: italic;
			display: inline-block;
			margin-top: 20px;
		}
	}
}
@import "styles/_wgt-current-alert-widget";

.autocomplete-suggestions {
    text-align: left; cursor: default; border: 1px solid #ccc; border-top: 0; background: #fff; box-shadow: -1px 1px 3px rgba(0,0,0,.1);

    /* core styles should not be changed */
    position: absolute; display: none; z-index: 9999; max-height: 254px; overflow: hidden; overflow-y: auto; box-sizing: border-box;
}
.autocomplete-suggestion { position: relative; padding: 0 .6em; line-height: 23px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 1.02em; color: #333; }
.autocomplete-suggestion b { font-weight: normal; color: #1f8dd6; }
.autocomplete-suggestion.selected { background: #f0f0f0; }



