body{
	position: relative;
}
.cmm-alert-modal{
	//display: none;
	position: fixed;
	background-color: rgba(0, 0, 0, 0);
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	max-height: 100%;
	z-index: 9999999;
	.trans(background-color);
	.transform(translateY(-200vh));

	&.position{
		.transform(translateY(-100vh));
	}

	&.show{
		background-color: rgba(0, 0, 0, 0.5);
	}

	.cmm-alert-container{
		width: 100%;
		height: 100%;
		.display(flex);
		.justify-content(center);
		.align-items(center);
		.wrapper-cmm-alert-modal{
			.trans();
			.transform(translateY(600px) scale(0));
			box-sizing: border-box;
			padding: 10px;
			overflow: hidden;
			position: relative;
			width: 100%;
			max-width: 450px;
			height:auto;
			max-height: 280px;
			background-color: #fff;
			.border-radius(4px);
			box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
			vertical-align: top;
			&:before{
				width: 100%;
				height: 4px;
				content: "";
				display: inline-block;
				left: 0px;
				top: 0px;
				background-color: #a2c037;
				position: absolute;
			}

			&.show{
				.transform(translateY(0px) scale(1));
			}

			a{
				text-decoration: none;
				color: #ccc;
				position: absolute;
				right: 10px;
				top: 5px;
			}

			h1{
				font-size: 18px;
				box-sizing: border-box;
				display: inline-block;
				width: 100%;
			}
			p{
				font-size: 16px;
				min-height: 76px;
				padding-bottom: 32px;
			}
			nav{
				position: absolute;
				left: 0px;
				bottom: 0px;
				width: 100%;
				background-color: rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
				padding: 10px;
				button{
					float: right;
					margin: 0px 5px;
					border:none;
					background: transparent;
					text-transform: uppercase;
					font-size: 16px;
					&:first-child{
						color: #37920c;
					}
				}
			}
		}
	}
}

