.container-login{
	.display(flex);
	.align-items(center);
	.justify-content(center);
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	max-height: 100%;
	background-color: #65a7c0;


	.inner-container-login{
		width: 100%;
		max-width: 450px;
		max-height: 500px;
		height: 100%;

		.alert{
			width: 100%;
			display: inline-block;
			height: auto;
			padding: 20px 20px;
			font-size: 22px;
			color: white;
			background-color: #f5f5f5;
			color: #333;
			padding-left: 50px;
			vertical-align: middle;
			position: relative;
			border-top: 4px solid;
			line-height: 25px;
			&:before{
				font-family: 'icomoon' !important;
			    left: 0px;
			    top: 0px;
			    content: "";
			    display: inline-block;
			    position: absolute;
			    width: 50px;
			    text-align: center;
			    vertical-align: middle;
			    height: 100%;
			    line-height: 66px;
			    font-size: 36px;
			}

			&.alert-success{
				border-top-color: #a2c037;
				&:before{
					content: "\e963";
    				color: #158336;

				}
			}
		}


		figure{
			margin: 0;
			padding: 0;
			width: 100%;
			height: auto;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			width: 100%;
			height: 150px;
			svg{
				display: inline-block;
				width: 80%;
			}
		}
		form{
			background-color: white;
			border-top:4px solid #a2c037;
			box-sizing: border-box;
			padding: 10px;

			p{
				color: #8f8f8f;
				font-size: 14px;
				box-sizing: border-box;
				padding: 0px 10px;
				&:before{
					display: inline-block;
					content: "";
					width: 5px;
					height: 5px;
					background-color: #8f8f8f;
					margin-right: 5px;
					vertical-align: middle;
				}
			}

			button.button{
				background-color: #1ea01f;
				.border-radius(0);
			}

			a{
				display: inline-block;
				vertical-align: top;
				width: 100%;
				text-align: center;
				font-weight: 700;
				color: #444444;
				font-size: 11px;
			}

			a.button{
			    cursor: pointer;
			    border: 1px solid #d7d7d7;
			    background-color: #29B6F6;
			    line-height: normal;
			    padding: 10px 20px;
			    text-decoration: none;
			    color: #FFF;
			    display: inline-block;
			    transition: all 0.3s;
			    vertical-align: middle;
			    width: auto;
			    background-color: #1ea01f;
				.border-radius(0);
				font-size: 16px;
			}
		}
	}
}