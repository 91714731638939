.current-alert-widget{
	overflow: hidden;
	.border-radius(8px 8px 4px 4px);
	width: 100%;
	//.calc(max-width, ~'(32vw) - (@{base-sidebar-width}/3)');
	width: 31%;
	display: inline-block;
	min-height: 430px;
	position: relative;
	vertical-align: top;
	background-color: #f4f4f4;
	margin-top: 1.5%;
	margin-left: 1.5%;
	//box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	box-shadow:  0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	//outline: 1px solid rgba(197, 197, 197, 0.7);
	outline-offset: -1px;
	text-align: left;

.inner-wgt-more-info{
	    cursor:pointer;
	    width:46px;
	    height:46px;
	    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	    .border-radius(56px);
	    background-color: rgba(0, 0, 0, 0);
	    float:right;
	    .transform(translateY(192px) translateX(23px));
	    .transition(all 385ms cubic-bezier(0.215, 0.61, 0.355, 1));
	    overflow:hidden;
	    position: absolute;
	    right: 0px;
	    z-index: 10000;


	    &:hover{
	      .transform(translateY(184px) translateX(-5px));
	       width:64px;
	       height:64px;
	       figure{
	       	&.onlytext{
				&:before{
					font-size: 42px;
		       		.transform(translateY(9px));
		       	}
	       	}
	       }
	    }

	    &.hidden{
	    	.transform(translateY(192px) translateX(60px));
	    }

	    &.highlight{
	    	.transform(translateY(192px) translateX(-10px));
	    }

	    &.active{
	      width:100%;
	      .calc(height, ~'(100%) - 74px');
	      background-color: rgba(0, 0, 0, 0.9);
	      .border-radius(0px);
	      .transform(translateY(40px) translateX(0px));

	      figure{
	        margin-top:0%;
	        background-size:auto 102%;

			figcaption{
				.opacity(1);
			}

			&.onlytext{

				background-color: rgba(255, 255, 255, 0.9);
				&:before{
					height: 0px;
					.opacity(0);
				}
				figcaption{
					background: transparent;
					position: relative;
					display: inline-block;
					vertical-align: middle;
					font-size: 18px;
					color: #333;
					line-height: 22px;
				}
			}
			.go-full-screen{
				width: 32px;
			    margin-left: 50%;
			    margin-top: -2px;
			    display: inline-block;
				svg{
					width: 32px;
					path{
						box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
					}
				}
			}
			button{
				.opacity(1);
				display: inline-block;
				margin-top: 10px;
			}
	      }
	    }

	    figure{
	    	cursor:default;
	      .transition(all 385ms cubic-bezier(0.215, 0.61, 0.355, 1));
	      margin:0;
	      margin-top:0px;
	      padding:0px;
	      min-width:100%;
	      height:100%;
	      background-repeat:no-repeat;
	      background-size:cover;
	      background-position:50%;

	      &.onlytext{
	      	background-color: #ff5722;
	      	&:before{
	      		.transition(all 385ms cubic-bezier(0.215, 0.61, 0.355, 1));
	      		.transform(translateY(0));
	      		.opacity(1);
	      		position: relative;
	      		overflow: hidden;
	      		font-family: 'icomoon' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				content: "\e94d";
				display: inline-block;
				text-align: center;
			    font-size: 36px;
			    width: 100%;
			    height: 100%;
			    line-height: 45px;
	      	}

	      	.go-full-screen{
	      		display: none;
	      	}
	      }

	      figcaption{
	      	/*min-width: 350px;*/
	      	.opacity(0);
	      	box-sizing: border-box;
		    padding: 15px;
		    font-size: 13px;
		    line-height: 15px;
		    position: absolute;
		    bottom: 0px;
		    padding-top: 20px;

background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
	      }
			a.go-full-screen{
				display: none;
			}

			button{
				outline: none;
				float: right;
				margin: 10px;
				background: transparent;
				border: 0;
				.opacity(0);
				display: inline-block;
				margin-top: -30px;
				i{
					font-size: 14px;
					text-align: center;
					line-height: 14px;
					text-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
				}
			}
	    }
  }


	header{
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: 40px;
		text-align: left;
		padding-left: 10px;
    	padding-top: 2px;
    	z-index: 500000;
    	position: relative;
		>i{
			background-color: #FFFFFF;
			.border-radius(40px);
			width: 54px;
		    height: 54px;
		    font-size: 48px !important;
		    margin-top:10px;
			display: none !important;
			box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		}

	    &.incendio{
	    	background-color: @clr-incendio;

			>i{
				text-align: center;
				color: @clr-incendio;
				&:before{
					color: @clr-incendio;
				}
			}
			.icon-iconos-alert-incendio{
				display: inline-block !important;
			}
	    }

	    &.robo{
	    	background-color: @clr-robo;

			>i{
				text-align: center;
				color: @clr-robo;
				&:before{
					color: @clr-robo;
				}
			}
			.icon-iconos-robo-alert{
				display: inline-block !important;
			}
	    }

	    &.panico{
	    	background-color: @clr-panico;

			>i{
				text-align: center;
				color: @clr-panico;
				font-size: 39px !important;
    			line-height: 48px;
				&:before{
					color: @clr-panico;
				}
			}
			.icon-iconos-panico-alert{
				display: inline-block !important;
			}
	    }

	    &.accidente{
	    	background-color: @clr-accidente;

			>i{
				text-align: center;
				color: @clr-accidente;
				font-size: 39px !important;
    			line-height: 48px;
				&:before{
					color: @clr-accidente;
				}
			}
			.icon-iconos-alert-accidente{
				display: inline-block !important;
			}
	    }

	    h2{
	    	margin-top: -24px;
	    	color: white;
	    	display: inline-block;
	    	font-size:18px;
	    	font-weight: 300;
	    	vertical-align: middle;
    		padding-top: 0px;
    		padding-left: 5px;
    		text-transform: uppercase;
	    	span{
	    		text-transform: uppercase;
	    		font-size: 27px;
	    	}
	    }
	    p{
	    	font-size: 16px;
	    	margin-bottom: 0px;
	    }

	    a{
	    	    position: absolute;
			    right: 5px;
    			top: 5px;
			    background-color: #e03333;
			    font-size: 12px;
			    padding: 6px 6px;
			    .border-radius(4px);
			    line-height: 15px;
			    color: #fff;
			    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
			    i{
			    	font-size: 14px !important;
			    }
	    }
	}

	fieldset{

		&.card{
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			display: inline-block;
			margin-top: 10px;
			float: inherit;
			.border-radius(2px);
			box-sizing: border-box;
			padding-left: 38px;
			padding-top: 4px;
			padding-bottom: 4px;
			background-color: white;
			background-image: url(../img/megafono_gray.svg);
			background-repeat: no-repeat;
			background-size: 10%;
			background-position: 1% 46%;
			label{
				line-height: 12px;
			}

			&.creator{
				background-color: #fafafa;
				box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
				background-image: url(../img/creator.svg);
			}
			&.emisor{
				background-color: #fafafa;
				box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
				background-size: 9%;
				background-position: 2% 47%;
				background-image: url(../img/emisor.svg);
			}
		}

		label{
			font-size: 12px;
			padding-left: 0px;
		}
		strong{
			vertical-align: top;
			color: black;
			font-size: 12px;
			&.light{
				font-weight: 400; 
			}
		}
	}

	.holder-map-alert{
		width: 100%;
		height: 175px;
		overflow: hidden;
		position: relative;
		&:after{
		    position: absolute;
		    left: 0px;
		    bottom: 0px;
		    height: 2px;
		    background-color: #d2d2d2;
		    content: "";
		    display: inline-block;
		    z-index: 5000;
		    width: 100%;
		}
		h5{
			font-size: 12px;
		}
		.inner-map{
			width: 100%;
			height: 110%;
			display: inline-block;
			vertical-align: top;
			background-color: #333;
			&:before{
			    content: "Coordenadas no disponibles";
			    display: inline-block;
			    text-align: center;
			    width: 100%;
			    height: 100%;
			    box-sizing: border-box;
			    padding-top: 21%;
			    font-size: 22px;
			}
		}
	}

	>footer{
		width: 100%;
		display: inline-block;
		margin-top: 30px;
		background-color: #d2d2d2;
		display: inline-block;
		vertical-align: bottom;

		>span.foot{
			text-indent: 10px;
			position: relative !important;
			left: 0px !important;
			top: 0px !important;
			display: inline-block !important;
			float: left !important;
			width: auto !important;
			text-align: left !important;
			font-size: 12px !important;
			color: black !important;
			vertical-align: middle;
			margin-top: 5px;
		}

		a{
			background-color: #fff;
			width: 42px;
			height: 42px;
			.border-radius(42px);
			text-align: center;
			float: right;
		    margin-top: -17px;
		    margin-right: 10px;
		    margin-bottom: 9px;
		    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
		    z-index: 200000;
		    position: relative;
			i{
				display: inline-block;

				color:#0d810e !important;
				font-size: 25px !important;
    			text-align: center;
    			line-height: 38px;
			}
			&:hover{
				background-color: #0d810e;
				i{
					color:white !important;
				}
			}
		}
	}
}

.overlay-image-not{
	.transform(translateY(-150%));
	position: fixed;
	.display(flex);
	width: 100vw;
	max-width: 100vw;
	height: 100vh;
	z-index:999999999;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0px;
	top: 0px;
	.align-items(flex-start);
	.justify-content(center);
	padding: 10px;
	overflow: auto;
	&.show{
		.transform(translateY(0%));
	}
	figure{
		padding: 30px;
		box-sizing: content-box;
		img{
			margin: 30px;
		}
	}
	a{
		position: fixed;
		right: 10px;
		top: 10px;
		text-decoration: none;
		outline: none;
		i{
			text-decoration: none;
			color: white;
			text-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
			font-size: 48px;
		}
	}

	button{
		position: fixed;
		left: 10px;
		top: 10px;
		text-decoration: none;
		outline: none;
		background-color: transparent;
		width: 48px;
		height: 48px;
		border: transparent;
		outline: none;
		i{
			max-width: 48px;
			height: 48px;
			text-decoration: none;
			color: white;
			text-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
			font-size: 48px;
			svg{
				width: 100%;
				height: 48px;
			}
		}
	}
}