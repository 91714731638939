// ==========================================================================
// Base – Mobile-First Grid
// ==========================================================================

// Micro Clearfix - http://nicolasgallagher.com/micro-clearfix-hack/
.clear:before, .clear:after { content: " "; display: table; } .clear:after { clear: both; }
.row:before, .row:after { content: ""; display: table; } .row:after { clear: both; }

// Rows
.row {
  position: relative;
  margin-left: -@grid-gutter;
  margin-right: -@grid-gutter;

  &.apply-flex{
    .display(flex);
  }
}
// Medium Device Row
@media only screen and (min-width: @breakpoint-m) {
  .row-m {
    position: relative;
    margin-left: -@grid-gutter-m;
    margin-right: -@grid-gutter-m;
  }
  .clear-m:before, .clear-m:after { content: ""; display: table; } .clear-m:after { clear: both; }
  .row-m:before, .row-m:after { content: ""; display: table; } .row-m:after { clear: both; }
}
// Large Device Row
@media only screen and (min-width: @breakpoint-l) {
  .row-l {
    position: relative;
    margin-left: -@grid-gutter-l;
    margin-right: -@grid-gutter-l;
  }
  .clear-l:before, .clear-l:after { content: ""; display: table; } .clear-l:after { clear: both; }
  .row-l:before, .row-l:after { content: ""; display: table; } .row-l:after { clear: both; }
}
// Extra Large Device Row
@media only screen and (min-width: @breakpoint-xl) {
  .row-xl {
    position: relative;
    margin-left: -@grid-gutter-xl;
    margin-right: -@grid-gutter-xl;
  }
  .clear-xl:before, .clear-xl:after { content: ""; display: table; } .clear-xl:after { clear: both; }
  .row-xl:before, .row-xl:after { content: ""; display: table; } .row-xl:after { clear: both; }
}

.total-container{
  width: 100vw;
  width: 100%;
  height: 100vh;
  vertical-align: top;
  display: inline-block;
  text-align: left;
  overflow-x: hidden;
  .trans();
  .opacity(.5);
}

// Container
.container {
  padding-left: @grid-gutter;
  padding-right: @grid-gutter;
  margin-left: auto;
  margin-right: auto;
}
.container-full {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}

.inner-panel{
  width: 100%;
  height: auto;
  padding:10px;
  box-sizing: border-box;
  margin: 10px;
  .calc(width, ~'100% - 20px'); 
  .border-radius(4px);
  background-color: #fff;
  figure{
    display: inline-block;
    text-align: center;
    margin: 0px;
    
    &.full-image{
      .calc(width, ~'100% + 20px');
      margin-left: -10px;
    }

    >img{
      width: 100%;
    }
  }
}

section{
  display: inline-block;
  margin-bottom:10px;

  &.sep__section{
    background-color: @base-highlight-color;
    height: 10px;
  }
}

// Medium Device Container
@media only screen and (min-width: @breakpoint-m) {
  .container, .container-full {
    width: @container-m;
  }
  .container-m {
    width: @container-m;
    padding-left: @grid-gutter-m;
    padding-right: @grid-gutter-m;
    margin-left: auto;
    margin-right: auto;
  }
  .container-full-m {
    width: @container-m;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}
// Large Device Container
@media only screen and (min-width: @breakpoint-l) {
  .container, .container-full {
    width: @container-l;
  }
  .container-l {
    width: @container-l;
    padding-left: @grid-gutter-l;
    padding-right: @grid-gutter-l;
    margin-left: auto;
    margin-right: auto;
  }
  .container-full-l {
    width: @container-l;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}
// Extra Large Device Container
@media only screen and (min-width: @breakpoint-xl) {
  .container, .container-full {
    width: @container-xl;
  }
  .container-xl {
    width: @container-xl;
    padding-left: @grid-gutter-xl;
    padding-right: @grid-gutter-xl;
    margin-left: auto;
    margin-right: auto;
  }
  .container-full-xl {
    width: @container-xl;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

// Mobile-first Grid Columns - Global Rules
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-1-2,
.col-1-3,
.col-2-3,
.col-1-4,
.col-3-4,
.col-1-5,
.col-2-5,
.col-3-5,
.col-4-5 {
  padding-left: @grid-gutter;
  padding-right: @grid-gutter;
  position: relative;
  float: left;
}

// Mobile-first Grid
.generate-grid (12);
.generate-grid (@index) when (@index > 0) {
  .generate-grid (@index - 1);
  .col-@{index} {
    width: @index / 12 * 100%;
  }
}
.col-1-2 { width: (6 / 12) * 100%; }
.col-1-3 { width: (4 / 12) * 100%; }
.col-2-3 { width: (8 / 12) * 100%; }
.col-1-4 { width: (3 / 12) * 100%; }
.col-3-4 { width: (9 / 12) * 100%; }
.col-1-5 { width: (2.4 / 12) * 100%; }
.col-2-5 { width: (4.8 / 12) * 100%; }
.col-3-5 { width: (7.2 / 12) * 100%; }
.col-4-5 { width: (9.6 / 12) * 100%; }
.col-full { width: 100%; }

// Mobile Push Offsets
.generate-push-offsets (11);
.generate-push-offsets (@index) when (@index > 0) {
  .generate-push-offsets (@index - 1);
  .push-@{index} {
    left: @index / 12 * 100%;
  }
}
.push-1-2 { left: (6 / 12) * 100%; }
.push-1-3 { left: (4 / 12) * 100%; }
.push-2-3 { left: (8 / 12) * 100%; }
.push-1-4 { left: (3 / 12) * 100%; }
.push-3-4 { left: (9 / 12) * 100%; }
.push-1-5 { left: (2.4 / 12) * 100%; }
.push-2-5 { left: (4.8 / 12) * 100%; }
.push-3-5 { left: (7.2 / 12) * 100%; }
.push-4-5 { left: (9.6 / 12) * 100%; }

// Mobile Pull Offsets
.generate-pull-offsets (11);
.generate-pull-offsets (@index) when (@index > 0) {
  .generate-pull-offsets (@index - 1);
  .pull-@{index} {
    left: -@index / 12 * 100%;
  }
}
.pull-1-2 { left: -(6 / 12) * 100%; }
.pull-1-3 { left: -(4 / 12) * 100%; }
.pull-2-3 { left: -(8 / 12) * 100%; }
.pull-1-4 { left: -(3 / 12) * 100%; }
.pull-3-4 { left: -(9 / 12) * 100%; }
.pull-1-5 { left: -(2.4 / 12) * 100%; }
.pull-2-5 { left: -(4.8 / 12) * 100%; }
.pull-3-5 { left: -(7.2 / 12) * 100%; }
.pull-4-5 { left: -(9.6 / 12) * 100%; }


// Medium Device Grid
@media only screen and (min-width: @breakpoint-m) {
  
  // Medium Device Grid Columns - Global Rules
  .col-1-m,
  .col-2-m,
  .col-3-m,
  .col-4-m,
  .col-5-m,
  .col-6-m,
  .col-7-m,
  .col-8-m,
  .col-9-m,
  .col-10-m,
  .col-11-m,
  .col-12-m,
  .col-1-2-m,
  .col-1-3-m,
  .col-2-3-m,
  .col-1-4-m,
  .col-3-4-m,
  .col-1-5-m,
  .col-2-5-m,
  .col-3-5-m,
  .col-4-5-m {
    padding-left: @grid-gutter-m;
    padding-right: @grid-gutter-m;
    position: relative;
    float: left;
  }

  // Medium Device Grid
  .generate-grid-m (12);
  .generate-grid-m (@index) when (@index > 0) {
    .generate-grid-m (@index - 1);
    .col-@{index}-m {
      width: @index / 12 * 100%;
    }
  }
  .col-1-2-m { width: (6 / 12) * 100%; }
  .col-1-3-m { width: (4 / 12) * 100%; }
  .col-2-3-m { width: (8 / 12) * 100%; }
  .col-1-4-m { width: (3 / 12) * 100%; }
  .col-3-4-m { width: (9 / 12) * 100%; }
  .col-1-5-m { width: (2.4 / 12) * 100%; }
  .col-2-5-m { width: (4.8 / 12) * 100%; }
  .col-3-5-m { width: (7.2 / 12) * 100%; }
  .col-4-5-m { width: (9.6 / 12) * 100%; }
  .col-full-m { width: 100%; }

  // Medium Device Push Offsets
  .generate-push-offsets-m (11);
  .generate-push-offsets-m (@index) when (@index > 0) {
    .generate-push-offsets-m (@index - 1);
    .push-@{index}-m {
      left: @index / 12 * 100%;
    }
  }
  .push-1-2-m { left: (6 / 12) * 100%; }
  .push-1-3-m { left: (4 / 12) * 100%; }
  .push-2-3-m { left: (8 / 12) * 100%; }
  .push-1-4-m { left: (3 / 12) * 100%; }
  .push-3-4-m { left: (9 / 12) * 100%; }
  .push-1-5-m { left: (2.4 / 12) * 100%; }
  .push-2-5-m { left: (4.8 / 12) * 100%; }
  .push-3-5-m { left: (7.2 / 12) * 100%; }
  .push-4-5-m { left: (9.6 / 12) * 100%; }

  // Medium Device Pull Offsets
  .generate-pull-offsets-m (11);
  .generate-pull-offsets-m (@index) when (@index > 0) {
    .generate-pull-offsets-m (@index - 1);
    .pull-@{index}-m {
      left: -@index / 12 * 100%;
    }
  }
  .pull-1-2-m { left: -(6 / 12) * 100%; }
  .pull-1-3-m { left: -(4 / 12) * 100%; }
  .pull-2-3-m { left: -(8 / 12) * 100%; }
  .pull-1-4-m { left: -(3 / 12) * 100%; }
  .pull-3-4-m { left: -(9 / 12) * 100%; }
  .pull-1-5-m { left: -(2.4 / 12) * 100%; }
  .pull-2-5-m { left: -(4.8 / 12) * 100%; }
  .pull-3-5-m { left: -(7.2 / 12) * 100%; }
  .pull-4-5-m { left: -(9.6 / 12) * 100%; }

}

// Large Device Grid
@media only screen and (min-width: @breakpoint-l) {
  
  // Large Device Grid Columns - Global Rules
  .col-1-l,
  .col-2-l,
  .col-3-l,
  .col-4-l,
  .col-5-l,
  .col-6-l,
  .col-7-l,
  .col-8-l,
  .col-9-l,
  .col-10-l,
  .col-11-l,
  .col-12-l,
  .col-1-2-l,
  .col-1-3-l,
  .col-2-3-l,
  .col-1-4-l,
  .col-3-4-l,
  .col-1-5-l,
  .col-2-5-l,
  .col-3-5-l,
  .col-4-5-l {
    padding-left: @grid-gutter-l;
    padding-right: @grid-gutter-l;
    position: relative;
    float: left;
  }

  // Large Device Grid
  .generate-grid-l (12);
  .generate-grid-l (@index) when (@index > 0) {
    .generate-grid-l (@index - 1);
    .col-@{index}-l {
      width: @index / 12 * 100%;
    }
  }
  .col-1-2-l { width: (6 / 12) * 100%; }
  .col-1-3-l { width: (4 / 12) * 100%; }
  .col-2-3-l { width: (8 / 12) * 100%; }
  .col-1-4-l { width: (3 / 12) * 100%; }
  .col-3-4-l { width: (9 / 12) * 100%; }
  .col-1-5-l { width: (2.4 / 12) * 100%; }
  .col-2-5-l { width: (4.8 / 12) * 100%; }
  .col-3-5-l { width: (7.2 / 12) * 100%; }
  .col-4-5-l { width: (9.6 / 12) * 100%; }
  .col-full-l { width: 100%; }

  // Large Device Push Offsets
  .generate-push-offsets-l (11);
  .generate-push-offsets-l (@index) when (@index > 0) {
    .generate-push-offsets-l (@index - 1);
    .push-@{index}-l {
      left: @index / 12 * 100%;
    }
  }
  .push-1-2-l { left: (6 / 12) * 100%; }
  .push-1-3-l { left: (4 / 12) * 100%; }
  .push-2-3-l { left: (8 / 12) * 100%; }
  .push-1-4-l { left: (3 / 12) * 100%; }
  .push-3-4-l { left: (9 / 12) * 100%; }
  .push-1-5-l { left: (2.4 / 12) * 100%; }
  .push-2-5-l { left: (4.8 / 12) * 100%; }
  .push-3-5-l { left: (7.2 / 12) * 100%; }
  .push-4-5-l { left: (9.6 / 12) * 100%; }

  // Large Device Pull Offsets
  .generate-pull-offsets-l (11);
  .generate-pull-offsets-l (@index) when (@index > 0) {
    .generate-pull-offsets-l (@index - 1);
    .pull-@{index}-l {
      left: -@index / 12 * 100%;
    }
  }
  .pull-1-2-l { left: -(6 / 12) * 100%; }
  .pull-1-3-l { left: -(4 / 12) * 100%; }
  .pull-2-3-l { left: -(8 / 12) * 100%; }
  .pull-1-4-l { left: -(3 / 12) * 100%; }
  .pull-3-4-l { left: -(9 / 12) * 100%; }
  .pull-1-5-l { left: -(2.4 / 12) * 100%; }
  .pull-2-5-l { left: -(4.8 / 12) * 100%; }
  .pull-3-5-l { left: -(7.2 / 12) * 100%; }
  .pull-4-5-l { left: -(9.6 / 12) * 100%; }

}

// Extra Large Device Grid
@media only screen and (min-width: @breakpoint-xl) {
  
  // Extra Large Device Grid Columns - Global Rules
  .col-1-xl,
  .col-2-xl,
  .col-3-xl,
  .col-4-xl,
  .col-5-xl,
  .col-6-xl,
  .col-7-xl,
  .col-8-xl,
  .col-9-xl,
  .col-10-xl,
  .col-11-xl,
  .col-12-xl,
  .col-1-2-xl,
  .col-1-3-xl,
  .col-2-3-xl,
  .col-1-4-xl,
  .col-3-4-xl,
  .col-1-5-xl,
  .col-2-5-xl,
  .col-3-5-xl,
  .col-4-5-xl {
    padding-left: @grid-gutter-xl;
    padding-right: @grid-gutter-xl;
    position: relative;
    float: left;
  }

  // Extra Large Device Grid
  .generate-grid-xl (12);
  .generate-grid-xl (@index) when (@index > 0) {
    .generate-grid-xl (@index - 1);
    .col-@{index}-xl {
      width: @index / 12 * 100%;
    }
  }
  .col-1-2-xl { width: (6 / 12) * 100%; }
  .col-1-3-xl { width: (4 / 12) * 100%; }
  .col-2-3-xl { width: (8 / 12) * 100%; }
  .col-1-4-xl { width: (3 / 12) * 100%; }
  .col-3-4-xl { width: (9 / 12) * 100%; }
  .col-1-5-xl { width: (2.4 / 12) * 100%; }
  .col-2-5-xl { width: (4.8 / 12) * 100%; }
  .col-3-5-xl { width: (7.2 / 12) * 100%; }
  .col-4-5-xl { width: (9.6 / 12) * 100%; }
  .col-full-xl { width: 100%; }

  // Extra Large Device Push Offsets
  .generate-push-offsets-xl (11);
  .generate-push-offsets-xl (@index) when (@index > 0) {
    .generate-push-offsets-xl (@index - 1);
    .push-@{index}-xl {
      left: @index / 12 * 100%;
    }
  }
  .push-1-2-xl { left: (6 / 12) * 100%; }
  .push-1-3-xl { left: (4 / 12) * 100%; }
  .push-2-3-xl { left: (8 / 12) * 100%; }
  .push-1-4-xl { left: (3 / 12) * 100%; }
  .push-3-4-xl { left: (9 / 12) * 100%; }
  .push-1-5-xl { left: (2.4 / 12) * 100%; }
  .push-2-5-xl { left: (4.8 / 12) * 100%; }
  .push-3-5-xl { left: (7.2 / 12) * 100%; }
  .push-4-5-xl { left: (9.6 / 12) * 100%; }

  // Extra Large Device Pull Offsets
  .generate-pull-offsets-xl (11);
  .generate-pull-offsets-xl (@index) when (@index > 0) {
    .generate-pull-offsets-xl (@index - 1);
    .pull-@{index}-xl {
      left: -@index / 12 * 100%;
    }
  }
  .pull-1-2-xl { left: -(6 / 12) * 100%; }
  .pull-1-3-xl { left: -(4 / 12) * 100%; }
  .pull-2-3-xl { left: -(8 / 12) * 100%; }
  .pull-1-4-xl { left: -(3 / 12) * 100%; }
  .pull-3-4-xl { left: -(9 / 12) * 100%; }
  .pull-1-5-xl { left: -(2.4 / 12) * 100%; }
  .pull-2-5-xl { left: -(4.8 / 12) * 100%; }
  .pull-3-5-xl { left: -(7.2 / 12) * 100%; }
  .pull-4-5-xl { left: -(9.6 / 12) * 100%; }

}