// ==========================================================================
// Base – Assets
// ==========================================================================

// Custom Buttons
.button {
  .border-radius(4px);
  cursor: pointer;
  border: 1px solid @base-button-br-color;
  background-color: #1ea01f;
  line-height: normal;
  padding: 10px 20px;
  text-decoration: none;
  color: @base-button-color;
  display: inline-block;
  transition: all 0.3s;
  &:hover, &:active { text-decoration: none; color: @base-button-color-hover;}
  &:hover { background: @base-button-bg-color-hover; }
  &.active{
    text-decoration: none; color: @base-button-color-hover;
    background: @base-button-bg-color-hover;
  }
  vertical-align: middle;

  &.small{
    font-size: @base-button-small-font-size;
    padding: 5px 10px;
  }
  &.nano{
    font-size: @base-button-nano-font-size;
    padding: 3px 6px;
  }
  &.big{
    font-size: @base-button-big-font-size;
    padding: 12px 24px;
  }

  &:focus {outline:0;}

  &.i-loader{
    &:hover{
      background-color: @base-highlight-color;
      color: @base-button-color;
    }
  }
  &.loader{
    &:hover{
      background-color: @base-highlight-color;
      color: @base-highlight-color;
    }
  }
}
.button-primary {
    
}
.button-secondary {
  // Add your styles here
}

.button-round {
  .border-radius(28px);
  border:2px solid white;
  padding: 0px !important;
  text-align: center;
  width: 42px;
  height: 42px;
  line-height: 42px;

  &.big{
    width: 51px;
    height: 51px;
    line-height: 51px;
  }
}

.button-blended{
  border:1px solid rgba(255, 255, 255, .5);
  background-color:rgba(255, 255, 255, 0);
  color: @base-button-color-hover;
}

// Button Styled as Link
.button-link {
  color: @base-button-color;
  text-decoration: underline;
  border: 0;
  background: transparent;
  padding: 0;
  &:hover { text-decoration: none; }
  &:active { outline: 0; }
}
