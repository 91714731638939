.wgt__info-user{
	display: inline-block;
	width: 100%;
	background-color: #3e3e3e;
	color: white;
	vertical-align: top;
	padding-top: 10px;
	padding-bottom: 10px;
	position: relative;

	&:after{
		content: "";
		display: inline-block;
		height: 20px;
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		.opacity(.3);
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

	}
	.wgt__info-user__profile{
		margin-bottom: 0px;
		width: 100%;
		display: inline-block;
		padding: 10px;
		box-sizing: border-box;
		i{
			display: inline-block;
			max-width: 30%;
			vertical-align: middle;
			max-height: 32px;
			color: white;
		}
		p{
			padding-left: 10px;
			margin-bottom: 0px;
			max-height: 32px;
			vertical-align: middle;
			display: inline-block;
			max-width: 85%;
			color: white;
			font-size: 14px;
			font-weight: 400;
			line-height: 14px;
			span{
				display: inline-block;
				line-height: 17px;
				width: 100%;
				font-size: 17px;
				font-weight: 700;
			}
			&:after{
				content: "";
				display: inline-block;
				height: 0px;
				border-bottom: 1px solid #4d4d4d;
				width: 100%;
			}
		}
	}
	.wgt__info-user__permissions{
		width: 100%;
		box-sizing: border-box;
		padding: 10px;
		display: inline-block;
		vertical-align: top;
		padding-top: 0px;
		margin: 0;
		span{
			width: 100%;
			box-sizing: border-box;
			padding: 0px 10px;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			font-size: 12px;
			color: #868686;
		}
		ul{
			margin: 0;
			padding: 0;
			border:0;
			width: 100%;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			list-style: none;
			li{
				display: inline-block;vertical-align: top;
				i{
					color: #a2c037;
				}
			}
		}
	}
}