@duration: 1s;
@color: #FFF;
  
@durationInternal: calc(@duration * 2);
@delay: calc(@duration / 2);


@keyframes rotate {
  25% { transform: rotate(-135deg); }
  50% { transform: rotate(-135deg); }
  75% { transform: rotate(-315deg); }
  100% { transform: rotate(-315deg); }
}

@keyframes hide1 {
  25% { left: -.5em; opacity: 0; }
  50% { left: 0; opacity: 1; }
}

@keyframes hide2 {
  25% { right: -.5em; opacity: 0; }
  50% { right: 0; opacity: 1; }
}

@keyframes container {
  25% { transform: translate3d(0, -50%, 0); width: .5em; }
  50% { transform: translate3d(-100%, -50%, 0); width: .5em; }
  75% { transform: translate3d(-50%, -50%, 0); width: 1em; }
}
.loader{
  position: relative;
  color: rgba(255, 255, 255, 0);
  i{
    .opacity(0);
  }
}

.loader:before{
  display: inline-block;
  content: "";
  margin:0;
  height: 60%;
  width: 60%;
  max-width: 36px;
  max-height: 36px;
  margin-top: 20%;
  animation: rotate 1000ms infinite linear;
  border: 5px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
}

@keyframes rotate {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}

.i-loader i{
  position: relative;
  color: rgba(255, 255, 255, 0);
}

.i-loader i{
  display: inline-block;
  vertical-align: middle;
  content: "";
  margin:0;
  height: 36px;
  width: 36px;
  overflow: hidden;
  max-width: 36px;
  max-height: 36px;
  animation: rotate 1000ms infinite linear;
  border: 5px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  &:before{
    content: "";
  }
}
