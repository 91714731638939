@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?q5v6xg');
  src:  url('../fonts/icomoon.eot?q5v6xg#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?q5v6xg') format('truetype'),
    url('../fonts/icomoon.woff?q5v6xg') format('woff'),
    url('../fonts/icomoon.svg?q5v6xg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-iconos-alerta:before {
  content: "\e934";
  color:inherit;
}
.icon-iconos-alert-accidente:before {
  content: "\e935";
}
.icon-iconos-alert-incendio:before {
  content: "\e936";
}
.icon-iconos-alert-top-bar:before {
  content: "\e937";
  color:inherit;
}
.icon-iconos-back:before {
  content: "\e938";
  color:inherit;
}
.icon-iconos-check:before {
  content: "\e939";
  color:inherit;
}
.icon-iconos-checkoff:before {
  content: "\e93a";
  color:inherit;
}
.icon-iconos-checkon:before {
  content: "\e93b";
  color:inherit;
}
.icon-iconos-clock:before {
  content: "\e93c";
}
.icon-iconos-community:before {
  content: "\e93d";
}
.icon-iconos-dashboard-icon:before {
  content: "\e93e";
}
.icon-iconos-date-pick:before {
  content: "\e93f";
}
.icon-iconos-delete:before {
  content: "\e940";
  color:inherit;
}
.icon-iconos-description:before {
  content: "\e941";
  color:inherit;
}
.icon-iconos-device-icon:before {
  content: "\e942";
  color:inherit;
}
.icon-iconos-elipse:before {
  content: "\e943";
  color:inherit;
}
.icon-iconos-email:before {
  content: "\e944";
  color:inherit;
}
.icon-iconos-file-clip:before {
  content: "\e945";
  color:inherit;
}
.icon-iconos-info:before {
  content: "\e946";
}
.icon-iconos-little-more:before {
  content: "\e947";
}
.icon-iconos-logocommisur:before {
  content: "\e948";
  color:inherit;
}
.icon-iconos-mail-send:before {
  content: "\e949";
}
.icon-iconos-megafono-icon:before {
  content: "\e94a";
}
.icon-iconos-memberi-con:before {
  content: "\e94b";
}
.icon-iconos-menu-icon:before {
  content: "\e94c";
  color:inherit;
}
.icon-iconos-message:before {
  content: "\e94d";
}
.icon-iconos-message-description:before {
  content: "\e94e";
  color:inherit;
}
.icon-iconos-more-icon:before {
  content: "\e94f";
}
.icon-iconos-Nay-icon:before {
  content: "\e950";
  color:inherit;
}
.icon-iconos-panico-alert:before {
  content: "\e951";
}
.icon-iconos-password:before {
  content: "\e952";
  color:inherit;
}
.icon-iconos-people-icon:before {
  content: "\e953";
  color:inherit;
}
.icon-iconos-picture-icon:before {
  content: "\e954";
  color:inherit;
}
.icon-iconos-piechart-icon:before {
  content: "\e955";
  color:inherit;
}
.icon-iconos-pin-map-icon:before {
  content: "\e956";
  color:inherit;
}
.icon-iconos-profile:before {
  content: "\e957";
}
.icon-iconos-profile-login:before {
  content: "\e958";
  color:inherit;
}
.icon-iconos-right-solid-arrow-menu:before {
  content: "\e959";
}
.icon-iconos-robo-alert:before {
  content: "\e95a";
}
.icon-iconos-save-icon:before {
  content: "\e95b";
}
.icon-iconos-search:before {
  content: "\e95c";
}
.icon-iconos-setup:before {
  content: "\e95d";
  color:inherit;
}
.icon-iconos-stat-icon:before {
  content: "\e95e";
  color:inherit;
}
.icon-iconos-table-sorting:before {
  content: "\e95f";
  color:inherit;
}
.icon-iconos-trash-icon:before {
  content: "\e960";
  color:inherit;
}
.icon-iconos-user-profile-log:before {
  content: "\e961";
}
.icon-iconos-write-message:before {
  content: "\e962";
  color:inherit;
}
.icon-iconos-Yey-icon:before {
  content: "\e963";
  color:inherit;
}


@font-face {
  font-family: 'signals';
  src:  url('../fonts/signals.eot?4ec4yp');
  src:  url('../fonts/signals.eot?4ec4yp#iefix') format('embedded-opentype'),
    url('../fonts/signals.ttf?4ec4yp') format('truetype'),
    url('../fonts/signals.woff?4ec4yp') format('woff'),
    url('../fonts/signals.svg?4ec4yp#signals') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-s-"], [class*=" icon-s"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'signals' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-s-iconos_bateria-baja:before {
  content: "\e900";
}
.icon-s-iconos_bateria-cargando:before {
  content: "\e901";
}
.icon-s-iconos_bateria-ok:before {
  content: "\e902";
}
.icon-s-iconos_signal:before {
  content: "\e903";
}
