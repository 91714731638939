.content_chart{
	margin-bottom: 22px;
	margin-top: -15px;
	header{
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		padding-right: 20px;
		margin-bottom: 20px;
		i{
			color: #a2c038;
		}

		h4{
			font-size: 13px;
			color: #454545;
			width: auto;
			display: inline-block;
		}

		ul{
			width: 50%;
		    float: right;
		    padding: 0;
		    margin: 0;
		    text-align: right;
			li{
				display: inline-block;
				font-size: 11px;
				line-height: 11px;
				box-sizing: border-box;
				padding-right: 15px;
				span{
					.border-radius(10px);
					margin-right:6px;
					width: 10px;
					height: 10px;
					display: inline-block;
					background-color: red;
					&.dot_incendio{
						background-color: #ce6b00;
					}
					&.dot_panico{
						background-color: #cfbd27;
					}
					&.dot_robo{
						background-color: #919191;
					}
					&.dot_accidente{
						background-color: #bf0411;
					}
				}
			}
		}
	}
}

.wgt_big_numbers{
	width: 100%;
	height: 100%;
	.display(flex);
	.align-items(center);
	.justify-content(space-around);
	.item-number{
		display: inline-block;
		h2{
			font-size: 80px;
			font-weight: 700;
			text-align: center;
			width: 100%;
		}
		span{
			color: #8f8f8f;
			font-size: 13px;
			text-align: center;
			width: 100%;
			display: inline-block;
			margin-top: 10px;
		}
	}
}

.wgt_percents_chart_header{
	h4{
		width: 48%;
		line-height: 13px;
		font-size: 13px;
		color: #454545;
		display: inline-block;
	}
	h5{
		width: 48%;
		line-height: 11px;
		font-size: 11px;
		color: #8f8f8f;
		display: inline-block;
		text-align: right;
		strong{
			font-weight: 700;
			color: #454545;
		}
	}
}
.wgt_percents_chart{
	width: 100%;
	.display(flex);
	.align-items(flex-start);
	margin-top: -30px;
	position: relative;
	&:after{
	    content: "";
	    display: block;
	    border-right: 1px solid #e7e7e7;
	    right: -15px;
	    top: 0px;
	    bottom: 0px;
	    height: 100%;
	    position: absolute;
	}

	.item-chart{
		width: 25%;
		position: relative;
		.wrapper-item-label{
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			.display(flex);
			.align-items(center);
			.justify-content(center);

			span{
				font-size: 22px;
				color: #8f8f8f;
				line-height: 22px;
    			text-align: center;
			}
		}

		span{
			width: 100%;
			display: inline-block;
			text-align: center;
			margin-top: 15px;
		}
	}
}