nav.toolbar_bottom_fixed{
	position: fixed;
	left: @base-sidebar-width;
	bottom: 0px;
	z-index: 100;
	.calc(width, ~'100vw - @{base-sidebar-width}');	
	background-color: #3e3e3e;
	height: 44px;
	.backface-visibility(hidden);
	.button{
		background-color: #626262;
		.border-radius(2);
		border: 1px solid #545454;
		color:#b0afaf;
		padding: 7px 12px;
		font-size: 12px;
		height: 32px;
		display: inline-block;
		vertical-align: middle;
		margin: 7px;
		font-weight: 700;
		span{
			display: inline-block;
			vertical-align: middle;
			margin-top: -5px;
		}

		i{
			display: inline-block;
			font-size: 24px;
		    border-right: 1px solid #5b5b5b;
		    margin-left: -5px;
		    margin-top: -5px;
		    vertical-align: middle;
			padding-left: 5px;
		    padding-right: 9px;
		    margin-right: 5px;
		}
		&:hover{
			color: white;
		}

		&.green{
			i{
				border-right: 1px solid #159016;
			}
			color: white;
			background-color: #1ea01f;
		}
	}
}