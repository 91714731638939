/*===============================
=            Sidebar            =
===============================*/

aside.sidebar{
	padding-top: 55px;
	.trans();
	position: fixed;
	width:@base-sidebar-width;
	height: 100vh;
	background-color: @base-bg-sidebar;
	position: fixed;
	left: 0px;
	top: 0px;	
	box-sizing: border-box;
	outline: 0px solid rgba(0, 0, 0, 0.5);
	z-index: 40;
	nav{
	  width: 100%;
	  height: auto;
	  box-sizing: border-box;
	  //margin: 10px;
	  overflow: hidden;
	  .calc(width, ~'100%');
	  //.border-radius(4px);
	  background-color: #5F5F5F;
	  list-style: none;
	  li{
	  	width: 100%;
	  	display: block;
	  	overflow-y: hidden;
    	max-height: 52px;
	  	&:last-child{
	  		a{
	  			&:after{
	  				//display: none;
	  			}
	  		}
	  	}
	  	&.sub-item{
	  		.trans();
	  	}
		&.active{
			max-height: inherit;
			a.sep__item{
				background-color: @base-navitem-bg-color-hover;
				color:@base-navitem-color-hover;
				&:after{
					height: 0px;
				}
			}
			a{
				background-color: @base-navitem-bg-color-hover;
				color:@base-navitem-color-hover;
				&:after{
					height: 0px;
				}
			}
			>ul{
				padding: 0;
				margin: 0;

				>li{
					a{
						font-size: @base-subitem-navitem-font-size;
						background-color: @base-navitem-bg-color-hover;;
						padding: 5px 5px 5px 22px;
						&:hover{
							background-color: @base-subitem-navitem-color-hover;
						}
					}
					&.active{
						a{
							background-color: @base-subitem-navitem-color-active;	
						}
					}
				}
			}
		}
		a{
			cursor: pointer;
			position: relative;
			display: inline-block;
			width: 100%;
			text-decoration: none;
			padding: 5px 10px;
			padding-bottom: 14px;
			font-size: 12px;
			background-color: @base-navitem-bg-color;
			color:@base-navitem-color;
			font-weight: @base-navitem-font-weight;
			/*border-bottom: 2px solid #575757;*/
    		/*border-top: 4px solid #626262;*/
			width: 100%;

			&.sep__item{
				&:after{
					display: block;
					content: "";
					width: 100%;
					margin-left: 0%;
					overflow: hidden;
					height: 1px;
					background-color: @base-navitem-sep-bg-color;
					position: absolute;
					left: 0px;
					bottom: 0px;
				}	
			}
			&:hover{
				background-color: @base-navitem-bg-color-hover;
				color:@base-navitem-color-hover;
				border-top-color: #515151;
				&:after{
					bottom: -1px;
				}
			}

			i{
				font-size: 24px;
				vertical-align: middle;
				display: inline-block;
				margin-right: 8px;
			}
			span{
				vertical-align: middle;
				display: inline-block;
			}

			&:after{
				content: "";
				display: block;
				position: absolute;
			    right: 8px;
			    top: 13px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 4.5px 0 4.5px 6px;
				border-color: transparent transparent transparent #ffffff;
			}
		}

		labe{
			font-size: 12px;
		}
	  }
	}

	.hardware_status{
		position: fixed;
		left: 0px;
		bottom: 5px;
		width: @base-sidebar-width;
		height: 26px;
		text-align: center;
		span{
			vertical-align: top;
			color: #00b35d;
			&.conn{
				width: 26px;
				height: 26px;
				display: inline-block;
				margin-right: 2px;
				i{
					font-size: 19px;
				}
				&.disconnected{
					color: #ac0101;
				}
			}
			&.batt{
				margin-left: 2px;
				width: 26px;
				height: 26px;
				display: inline-block;
				i{
					display: none;
					font-size: 18px;
    				margin-top: 2px;
				}
				&.pluged-in{
					i{
						&:nth-child(1){
							display: block;
						}
					}
				}
				&.charged{
					i{
						&:nth-child(2){
							display: block;
						}
					}
				}
				&.low-battery{
					i{
						&:nth-child(3){
							display: block;
						}
					}
				}
			}
		}
	}
}



.side-container{
	position: relative;
	.trans_slow();
	.calc(width, ~'100vw - @{base-sidebar-width}');
	height: auto;
	margin-left:@base-sidebar-width;
	.opacity(1);
	padding: 15px;
	z-index: 5;
	position: relative;
	.calc(height, ~'100vh - 55px');
	margin-top: 55px;
	overflow: auto;
	.calc(height, ~'100vh - 99px');
	.substract-toolbar{
		.calc(height, ~'100vh - 99px');
	}

	&.addOverflow{
		overflow: hidden !important;
	}

	&.fadeLeft{
		margin-left:-100vw;
		.opacity(0);
	}

	&.interior-container{
		.opacity(0);
		position: absolute;
		overflow-y: auto;
		overflow-x: hidden;
		left: 0px;
		top: 0px;
		z-index: 4;
		&.show-container{
			.opacity(1);
		}
	}
}


/*=====  End of Sidebar  ======*/


/*=====     Dinamic BG      ======*/

.bg-dinamic{
	position: fixed;
	z-index: 2;
	background-color:#67aac2;
	right: 0px;
	top: 0px;
	width: 100%;
	height: 300px;
	.calc(width, ~'100vw - @{base-sidebar-width}');
	.trans_slow();
	&.fadeLeft{
		right: 20vw;
	}

	.wrapper_bg_dinamic{
		width: 100%;
		height: 100vh;
		position: relative;
		background-color: #67aac2;
		&:before{
			content: "";
			display: inline-block;
			width: 150%;
			height: 285px;
			background-color: blue;
			left: 0px;
			bottom: 0px;
			position: absolute;
			/*
			background: -moz-linear-gradient(top, rgba(103,170,194,1) 33%, rgba(103,170,194,0.65) 48%, rgba(103,170,194,0.6) 50%, rgba(103,170,194,0.19) 84%, rgba(103,170,194,0) 100%); 
			background: -webkit-linear-gradient(top, rgba(103,170,194,1) 33%,rgba(103,170,194,0.65) 48%,rgba(103,170,194,0.6) 50%,rgba(103,170,194,0.19) 84%,rgba(103,170,194,0) 100%); 
			background: linear-gradient(to bottom, rgba(103,170,194,1) 33%,rgba(103,170,194,0.65) 48%,rgba(103,170,194,0.6) 50%,rgba(103,170,194,0.19) 84%,rgba(103,170,194,0) 100%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#67aac2', endColorstr='#0067aac2',GradientType=0 ); 
			*/
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,67aac2+100&0+0,1+100 */
			background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(103,170,194,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(103,170,194,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(103,170,194,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#67aac2',GradientType=0 ); /* IE6-9 */

			}
	}
}

/*=====  End of Dinamic BG  ======*/


/*============================================
=            Mobile Header Helper            =
============================================*/

header.mobile-sidebar-helper{
	width: 100%;
	height: @base-header-sidebar-mobile-height;
	position: fixed;
	left: 0px;
	top: 0px;
	display: none;
	background-color: @base-navitem-bg-color;
	button{
		display: inline-block;
		vertical-align: middle;
	}
}

/*=====  End of Mobile Header Helper  ======*/

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (max-width : 420px) 
{
	header.mobile-sidebar-helper{
		display: block;
		box-sizing: border-box;
		padding: 7px;
		z-index: 3
	}

	.side-container{
		width: 100%;
		margin-left: 0px;
		margin-top: @base-header-sidebar-mobile-height;
		.transform(translateX(0%));
		&.on{
			//.transform(translateX(50%));
		}
	}

	aside.sidebar{
		padding-bottom: 96px;
		.calc(height, ~'100vh - @{base-header-sidebar-mobile-height}');
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		margin-top: @base-header-sidebar-mobile-height;
		.transform(translateX(-100%));
		&.on{
			.transform(translateX(0%));
			outline:@base-sidebar-width solid rgba(0, 0, 0, 0.5);
		}
	}
}

