.wgt__toolbar-top{
	float: right;
	height: 100%;
	display: inline-block;
	vertical-align: top;
	ul{
		display: inline-block;
		vertical-align: top;
		margin: 0;
		padding: 0;
		height: 100%;
		>li{
			padding: 0px 10px;
			height: 100%;
			display: inline-block;
			vertical-align: middle;
			border-left: 1px solid #f3f3f3;
			&:last-child{
				border-right: 1px solid #f3f3f3;
				padding-right: 0px;
			}
			a{
				padding-top: 3px;
				display: inline-block;
				text-decoration: none;
				vertical-align: middle;
				cursor: pointer;
				&.button__message{
					white-space: nowrap;
					position: relative;
					span{
						display: inline-block;
						text-align: right;
						color: #0d810e;
						font-weight: 700;
						text-decoration: none;
						font-size: 10px;
						line-height: 14px;
						vertical-align: middle;
					}
					i{
						display: inline-block;
						font-size: 46px;
						color: #0d810e;
						vertical-align: middle;
					}

				}

				&.button__alert{
					padding: 3px 15px;
					white-space: nowrap;
					position: relative;
					height: 53px;

					span{
						display: inline-block;
						position: absolute;
					    left: 0px;
					    top: 10px;
					    color: #fff;
					    width: 100%;
					    z-index: 2000;
					    text-align: center;
					}

					i{
						display: inline-block;
						font-size: 38px;
						color: #919191;
						vertical-align: middle;
						&.icon-iconos-delete{
							display: none;
						}
					}
					&:hover{
						background-color: #9f010c;
						color: white;
						i{
							color: white;
						}
						span{
						    color: #9f010c;
						}
					}

					&.active{
						background-color: #9f010c;
						color: white;
						i{
							color: white;
							&.icon-iconos-alert-top-bar{
								display: none;
							}
							&.icon-iconos-delete{
								display: inline-block;
							}

						}

						>span{
							display: none;
						}


						&:before{
							display: none;
						    color: #9f010c;
						}
						.wgt__alert-list{
							display: block;
							background-color:rgba(255, 255, 255, 0.9);
						}
					}

					.wgt__alert-list{
						text-align: right;
						position: absolute;
						cursor: default;
						right:-99px;
						top: 54px;
						.calc(width, ~'100vw - @{base-sidebar-width}');
						.calc(height, ~'100vh - 54px');
						display: none;
						background-color:rgba(255, 255, 255, 0.9);
						box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.4);
						white-space: normal;
						overflow-x:hidden;
						overflow-y:auto;
						padding-right: 20px;
						box-sizing: border-box;
						padding-bottom: 20px;
						z-index: 99999999;
						h3{
							cursor: default;
							display: inline-block;
							width: 100%;
							color: #80050e;
							font-size: 10px;
							font-weight: 700;
							margin: 0;
							box-sizing: border-box;
							padding: 9px 9px;
    						line-height: 18px;
    						button{
    							cursor: pointer;
    							font-size: 10px;
    							border: none;
    							color: #787878;
    							background-color: #fff;
    							float: right;
    							border-bottom: 3px solid #a2c037;
    							line-height: 10px;
    						}
						}
						ul{
							border-top: 6px solid #92020c;
							white-space: normal;
							width: 400px;
							cursor: default;
							li{
								cursor: default;
								padding: 8px 0px;
								min-width: 400px;
								display: inline-block;
								border-bottom: 1px solid #e4e4e4;
								background-color: #f0f0f0;
								>i{
									display: inline-block;
									width: 44px;
									height: 44px;
									.border-radius(44px);
									vertical-align: middle;
									margin-right: 10px;
    								margin-left: 8px;

    								&.tipo-incendio{
										background-color: @clr-incendio;
										&:before{
											content: "\e936";
										}

    								}
    								&.tipo-robo{
    									background-color: @clr-robo;
    									&:before{
    										content: "\e95a";
    									}

    								}
    								&.tipo-panico{
										background-color: @clr-panico;
										&:before{
											content: "\e951";
										}
    								}
    								&.tipo-accidente{
										background-color: @clr-accidente;
										&:before{
											content: "\e935";
										}
    								}
								}
								h4{
									display: inline-block;
									vertical-align: middle;
									font-size: 10px;
									line-height: 10px;
									font-weight: 800;
									color: #222120;
									position: relative;
									height: 42px;
									padding-top: 5px;
									p{
										margin: 0;
										font-size: 10px;
										color: #8f8f8f;
										line-height: 10px;
									}
									span{
										color: #454545;
										font-weight: 500;
										font-size: 9px;
										left: 0px;
										bottom: 0px;
										position: absolute;
									}
								}

								button{
									float: right;
									border: none;
									display: inline-block;
									background-color: #f0f0f0;
									font-size: 22px;
									margin-right: 6px;
									margin-top: 4px;
									line-height: 34px;
									.border-radius(2px);
									i{
										font-size: 32px;
										color: #d3d3d3;
									}
									&:hover{
										background-color: #fff;
									}
								}
							}
						}
					}

					&:hover{
						&:not(.empty){
							.wgt__alert-list{
								display: block;
								background-color:rgba(255, 255, 255, 0.9);
							}
						}
					}
				}
				&.button__settings{
					padding: 3px 15px;
					white-space: nowrap;
					i{
						display: inline-block;
						font-size: 43px;
						color: #919191;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

.button_config_drop_down{
	position: relative;
	&:hover{
		background-color: #94c11e;
		a{
			i{
				color: white !important;
			}
		}
		.config_dropdown{
			display: block;
		}
	}

	.config_dropdown{
		display: none;
		position: absolute;
		right: 0px;
		top: 54px;
		width:160px;
		background-color: #8bb900;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
		z-index: 9999999999;
		.backface-visibility(hidden);
		ul{
			padding: 0;
			margin: 0;
			display: inline-block;
			vertical-align: top;
			width: 100%;
			li{
				background-color: #f8f4f5;
				width: 100%;
				margin: 0;
				padding: 0;
				&:hover{
					background-color: #646464;
					fieldset{
						label{
							color: #FFF;
						}
					}
					a{
						color: #FFF;
						i{
							svg{
								max-width: 18px;
								path{
									fill:#FFF;
								}
							}
						}
					}
				}
				&:nth-child(1){
					margin-top: 10px;
				}
				fieldset{
					padding: 10px;
					display: inline-block;
					vertical-align: top;
					text-align: left;
				}
				a{
					width: 100%;
					display: inline-block;
					vertical-align: top;
					padding: 0px 10px;
					text-align: left;
					font-size: 12px;
    				color: #8f8f8f;
				}
				i{
					display: inline-block;
					max-width: 22px;
					margin-right: 8px;
					text-align: center;
					svg{
						max-width: 18px;
						path{
							fill:#8f8f8f;
						}
					}
				}
			}
		}
	}
}