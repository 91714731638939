._wgt_left_info{
	height: 100%;
    box-sizing: border-box;
    padding: 15px;
    color: white;
    padding-left: 20px;


	&:before{
        border-radius: 40px;
        background-clip: padding-box;
        width: 80px;
        height: 80px;
        font-size: 82px;
        display: inline-block;
        background-color: #fff;

        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: white;
        font-size: 67px;
        text-align: center;
        line-height: 76px;
	}

    &.incendio{
        background-color: @clr-incendio;
        &:before{
            content: "\e936";
            color:@clr-incendio ;
        }
    }
    &.panico{
        background-color: @clr-panico;
        &:before{
            content: "\e951";
            color:@clr-panico ;
        }
    }
    &.robo{
        background-color: @clr-robo;
        &:before{
            content: "\e95a";
            color:@clr-robo ;
        }
    }
    &.accidente{
    	background-color: @clr-accidente;
		&:before{
            content: "\e935";
			color:@clr-accidente ;
		}
    }

    h2{
    	color: white;
    	display: inline-block;
    	font-size: 32px;
    	font-weight: 300;
    	span{
    		font-size: 27px;
    	}
    }
    p{
    	font-size: 16px;
    	margin-bottom: 0px;
    }
}