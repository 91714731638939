aside.header-bar{
	width: 100vw;
	max-width: 100%;
	background-color: @base-toolbar-color;
	outline: 2px solid @base-highlight-color;
	padding: 10px;
	box-sizing: border-box;
	max-height: 60px;
	nav{
		figure{
			img{
				width: 100%;
			}
		}
	}
	&.fixed-bar{
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: 9999;
	}
}