._wgt_left_tabs{
	display: inline-block;
	width: 100%;
	height: 100%;
	nav{
		display: table;
		.calc(width, ~'100% + 30px');
		height: 42px;
		border-bottom: 1px solid #e4e4e4;
		text-align: center;
		margin-left: -15px;
		margin-right: -15px;
		white-space: nowrap;
		li{
			display: table-cell;
			vertical-align: middle;
    		margin-top: 7px;
    		position: relative;
			&.active{
				border-right: 1px solid #e4e4e4;
				border-left: 1px solid #e4e4e4;

				&:first-child{
					border-right: 1px solid #e4e4e4;
					border-left:none;
				}
				&:last-child{
					border-left: 1px solid #e4e4e4;
					border-right:none;
				}

				&:before{
					height: 5px;
				}
				&:after{
					.opacity(1);
				}

				&.mapa{
					&:before{
						background-color: #cabd02;
					}
					a{
						i{
							color:#cabd02;
						}
					}
				}
				&.descripcion{
					&:before{
						background-color: #0d810e;
					}
					a{
						i{
							color:#0d810e;
						}
					}
				}
				&.comentarios{
					&:before{
						background-color: #3f3f3f;
					}
					a{
						i{
							color:#3f3f3f;
						}
					}
				}
			}



			&:before{
				content: "";
				display: inline-block;
				position: absolute;
				left: 0px;
				top: 0px;
				width: 100%;
				height: 3px;
				.opacity(1);
				.trans();
				background-color: #f1f1f1;
			}

			&:after{
				content: "";
				display: inline-block;
				position: absolute;
				left: 0px;
				bottom: -1px;
				width: 100%;
				height: 0px;
				border-bottom:  1px solid white;
				.opacity(0);
				.trans();
			}
			a{
				box-sizing: border-box;
				padding:0px 10px;
				text-decoration: none;
				display: inline-block;
				width: 100%;
				vertical-align: middle;
				text-align: center;
				color: #d7d7d7;
				font-size: 12px;
				line-height: 12px;
				i{
					font-size:22px;
					display: inline-block;
					vertical-align: middle;
				}
				span{
					text-align: left;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}

	._wgt_left_tabs_content{
		display: inline-block;
		width: 100%;
		height: 71%;
		margin-top: 15px;
		.wrapper-mapa{
			.calc(height, ~'100%');
			background-color: #d7d7d7;
			display:none;
		}
		.wrapper-descripcion{
			.calc(height, ~'100%');
			background-color: #FFF;
			display: none;
			overflow-y: auto;
			figure{
				margin: 0;
				width: 100%;
				display: inline-block;
				img{
					width: 100%;
				}
			}

			&:empty{
				&:before{
					content: "Sin Adjuntos";
					font-size: 11px;
					text-align: center;
					width: 100%;
					display: inline-block;
				}
			}
		}
		.wrapper-comentarios{
			display: none;
			.calc(height, ~'100%');
			background-color: #FFF;
			h4{
				font-size: 13px;
				color: #444444;
				display: inline-block;
				width: 100%;
			}
			textarea{
				display: inline-block;
				width: 100%;
				height: 85%;
			}
		}

		div{
			&.show{
				display: block;
			}
		}
	}


}