.section_dashboard{
	.calc(height, ~'100vh - 55px');
}
.addpadding{
	padding-left: 15px;
	padding-right: 15px;
}
.for_dashboard{
	background-color: #fff;
	box-sizing: border-box;
	padding: 20px;
	margin-top: 0px !important;
	border-top: 4px solid;
	box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
	padding-top: 5px;
	.border-radius(1px);
	header{
		margin-left: -10px;
		h4{
			display: inline-block;
			font-size: 16px;
			width: 62%;
			i{
				font-size: 22px;
    			vertical-align: middle;
			}
			span{
				font-size: 16px;
				display: inline-block;
				vertical-align: middle;
			}
		}
		h5{
			font-size: 13px;
			width: 37%;
		}

		a.boton_ver_todos_wgt{
			cursor: pointer;
		    font-size: 12px;
		    border: none;
		    color: #787878;
		    background-color: #fff;
		    float: right;
		    border-bottom: 2px solid #a2c037;
		    line-height: 10px;
		    text-decoration: none;
		    margin-top: 10px;
		    margin-right: -7px;
		    padding-bottom: 2px;
		}
	}

	&.green{
		border-top-color: #84c900;
	}

	&.orange{
		border-top-color: #d6bb04;
		width: 49%;
	}

	&.grey{
		border-top-color: #909090;
		width: 49%;
    	margin-left: 2%;
	}

	&.red{
		border-top-color: #ac0101;
		width: 49%;
		padding-bottom: 5px;
	}

	&.lightgreen{
		border-top-color: #00b35d;
		width: 49%;
		margin-left: 2%;
		padding-bottom: 5px;
	}

	.wgt_percents_chart{
		&:after{
			border: none;
		}
	}

	.wgt_last_alerts{
		padding-top: 10px;
		ul{
			margin: 0;
			padding: 0;
			list-style: none;
			margin-left: -7px;
			margin-right: -7px;
			li{
				display: inline-block;
				width: 100%;
				border-bottom: 1px solid #eaeaea;
				padding-bottom: 6px;
    			padding-top: 6px;
				&:last-child{
					border-bottom: transparent;
				}

				.item_alerta{
					i.alert_type{
						.border-radius(50px);
						width: 36px;
					    height: 36px;
					    display: inline-block;
					    font-size: 28px;
					    text-align: center;
					    line-height: 36px;
					    vertical-align: middle;
					    color: #FFF;

					    &.alr_incendio{
					    	background-color: @clr-incendio;
					    }
					    &.alr_panico{
					    	background-color: @clr-panico;
					    }
					    &.alr_robo{
					    	background-color: @clr-robo;
					    }
					    &.alr_accidente{
					    	background-color: @clr-accidente;
					    }
					}
					h5{
						display: inline-block;
						vertical-align: middle;
						width: 58%;
						font-size: 11px;
						color: #222120;
						text-transform: uppercase;
						line-height: 15px;
						box-sizing: border-box;
    					padding-left: 15px;
						span{
							text-transform: capitalize;
							display: inline-block;
							width: 100%;
							font-size: 10px;
							color: #8f8f8f;
							text-overflow:ellipsis;
							overflow: hidden;
						}
					}

					.time_stamp{
						margin: 0;
						display: inline-block;
						font-size: 10px;
						color: #454545;
						line-height: 12px;
    					vertical-align: middle;
    					span{
    						color: #0d810e;
    					}
					}

					a.ver_mas_alerta{
						float: right;
						color: #dddddd;
						font-weight: 300;
						text-decoration: none;
						margin-top: 4px;
						i{
							font-size: 26px;
						}
					}
				}
			}
		}
	}

	.wgt_last_messages{
		ul{
			margin: 0;
			padding: 0;
			list-style: none;
			margin-left: -7px;
			margin-right: -7px;
			li{
				display: inline-block;
				width: 100%;
				border-bottom: 1px solid #eaeaea;
    			padding-bottom: 4px;
    			padding-top: 4px;
				&:last-child{
					border-bottom: transparent;
				}
				.item_mensaje{
					position: relative;
					margin-left: 9px;
					&:before{
						position: absolute;
						left: -11px;
					    top: 4px;
						display: inline-block;
						content: "";
						background-color: #1c94d8;
						.border-radius(4px);
						width: 4px;
						height: 4px;
					}
					h5{
						width: 80%;
						display: inline-block;
						vertical-align: middle;
						font-size: 11px;
						color: #8f8f8f;
						font-weight: 500;
						line-height: 14px;
						strong{
							display: inline-block;
							width: 100%;
							font-weight: 700;
							color: #454545;
							line-height: 14px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow:ellipsis;
						}
					}

					.time_stamp{
						width: 20%;
						margin: 0;
						display: inline-block;
						font-size: 10px;
						color: #454545;
						line-height: 12px;
    					vertical-align: middle;
    					text-align: right;
    					span{
    						color: #0d810e;
    					}
					}
				}
			}
		}
	}
}