
/*
th, td { white-space: nowrap; }
    div.dataTables_wrapper {
        width: 100%;
        margin: 0 auto;
}
*/

.DTFC_RightBodyWrapper{
	overflow: hidden;
	position: relative;
	border-left: 1px solid silver;
}

.DTFC_RightBodyLiner{
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.DTFC_RightHeadWrapper{
	border-left: 1px solid silver;
	z-index: 40;
	table{
		box-shadow: none;
	}
}

.data-table{
	.border-radius(2px);
	display: none;
}
.dataTables_wrapper{

	/*
	.data-table-wrapper{
		width: 100%;
		overflow-x:auto;
	}
	*/

	.inner_dataTables_wrapper{
		width: 100%;
		overflow-x:auto;
	}

	.data-table{
		display: table;
	}
	.bottom{
		margin-top: 5px;
		background-color: #66a8c1;
	}
	table{
		.border-radius(2px);
		//box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
		thead, tfoot{
			tr{
				line-height: 10px;
				th{
					padding-right: 30px;
					background-color: #f0f0f0;
					font-size: 11px;
					border-right: 1px solid #e7e7e7;
					white-space: nowrap;
					&:last-child{
						border-right: none;
					}

					&.sorting{
						background-image: url(../img/sorting.svg);
						background-position: 100% 50%;
						background-repeat: no-repeat;
						background-size: contain;
					}
					&.sorting_desc{
						background-image: url(../img/sorting_asc.svg);
						background-position: 100% 50%;
						background-repeat: no-repeat;
						background-size: contain;
					}
					&.sorting_asc{
						background-image: url(../img/sorting_desc.svg);
						background-position: 100% 50%;
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}
		}

		tbody{
			background-color: #fff;
			tr{
				background-color: #fff;
				line-height: 14px;
				&:hover{
					td{
						background-color: #e7e7e7 !important;
					}
				}

				&.even{
					background-color: #f6f6f6;
					td{
						background-color: #f6f6f6;
					}
				}
				td{
					white-space: nowrap;
					background-color: #fff;
					font-size: 11px;
					border-right: 1px solid #e7e7e7;
					position: relative;
					&:last-child{
						border-right: none;
						&.t__actions{
							text-align: center;
						}
					}
					.button{
						padding: 2px 4px;
						background-color: #ff9800;
						display: inline-block;
						margin-top: -10px;
						margin-bottom: -10px;
					}
					&.middle-align{
						span{
							vertical-align: middle;
						}
						i{
							vertical-align: middle;
						}
					}

					i{
						font-size: 16px;
						display: inline-block;
					}
					span{
						display: inline-block;
						vertical-align: middle;
						white-space: nowrap;

						&.limit-small{
							max-width: 150px;
							overflow: hidden;
							text-overflow:ellipsis;
							position: relative;
							&:hover{
								overflow:visible;
								white-space: normal;
								position: absolute;
								background-color: white;
								top: 0px;
								z-index: 100;
								box-sizing: border-box;
								padding: 10px;
								border: 1px solid silver;
								max-width: 100%;
								right: 0px;
								left: 0px;
								box-shadow: 0px 2px 4px 0px rgba(0,0,0,.3);
								text-overflow:initial !important;
								word-wrap: break-word;
							}
						}
						&.c_pan{
							&:before{
								 background-color: @clr-panico;
							}
						}
						&.c_inc{
							&:before{
								 background-color: @clr-incendio;
							}
						}
						&.c_rob{
							&:before{
								 background-color: @clr-robo;
							}
						}
						&.c_acc{
							&:before{
								 background-color: @clr-accidente;
							}
						}

					}
				}
			}
		}
	}

	.bottom{
		float: right;
		margin-top: 25px;
		.dataTables_paginate{
			float: right;
				>a{
					&:first-child{
						.border-radius(4px 0px 0px 4px);
					}
					&:last-child{
						border-right: 1px solid #dbdbdb;
						.border-radius(0px 4px 4px 0px);
					}
				}
				box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
				.paginate_button{
					text-decoration: none;
					border: 1px solid #dbdbdb;
					background-color: #fff;
					padding: 10px 14px;
					color: #454545;
					font-size: 12px;
					font-weight: 700;
					border-right: 0px;
					cursor: pointer;

					&.current{
						background-color: #a2c037;
						border-top-color: #a2c037;
						border-bottom-color: #a2c037;
						color: white;
					}
					&.disabled{
						background-color: #fff;
						.opacity(1);
						color: #dedede;
					}
					[disabled="disabled"]{
						background-color: #fff;
						.opacity(1);
						color: #dedede;
					}
				}

				.ellipsis{
					text-decoration: none;
					border: 1px solid #dbdbdb;
					background-color: #fff;
					padding: 10px 14px;
					color: #454545;
					font-size: 12px;
					font-weight: 700;
					border-right: 0px;
				}
		}
	}
}

.marker-color{
	&:before{
			margin-right: 4px;
			content: "";
			display: inline-block;
			.border-radius(20px);
			width: 4px;
			height: 18px;
			vertical-align: middle;
		}
	}