body{
	aside.sidebar{
			nav{
				li{
					&.sub-item{
						max-height:100%;
						position: relative;
					}
							label{
								cursor: pointer;
								position: relative;
								display: inline-block;
								width: 100%;
								text-decoration: none;
								padding: 10px;
								background-color: @base-navitem-bg-color;
								color:@base-navitem-color;
								font-weight: @base-navitem-font-weight;
								width: 100%;
								font-size: 12px;
								position: relative;
								border-bottom: 2px solid #575757;
    							border-top: 4px solid #626262;
									&:hover{
										background-color: @base-navitem-bg-color-hover;
										color:@base-navitem-color-hover;
										border-top-color: #515151;
										&:after{
											bottom: -1px;
										}
									}
									&:after{
										content: "";
										direction: inline-block;
										position: absolute;
										right: 5px;
										top: 50%;
										width: 0;
										height: 0;
										border-style: solid;
										border-width: 0 8px 9px 8px;
										border-color: transparent transparent @base-h1-color transparent;
										margin-top: -4px;
										.opacity(.2);
										.trans();
										.rotate(180deg);
									}
									&[for*="sub__"]{
										border-bottom: 1px solid silver;
									}
								i{
									font-size: 24px;
									vertical-align: middle;
									display: inline-block;
									margin-right: 8px;
								}
								span{
									vertical-align: middle;
									display: inline-block;
								}
							}

							input[type="checkbox"]{
								display: none;
							}
							input[type="radio"]{
								display: none;
							}
							>ul{
								overflow: hidden;
								width: 100%;
								display:block;
								vertical-align: top;
								position: relative;
								max-height:0px;
								min-height: 0px;
								margin: 0;
								padding: 0px;
								.trans();
								li{
									border-left: 4px solid @base-h1-color;
									border-bottom: 1px solid @base-h1-color;
									a{
										background-color: #5F5F5F;
										color: white;
									}
								}
							}
							label:has(+input[type="radio"]){
								color: red !important;
							}
							[type="checkbox"] + ul{
								max-height:0px;
							}
							[type="checkbox"]:checked + ul{
								max-height:600px;
							}
							[type="radio"] + ul{
								max-height:0px;
							}
							[type="radio"]:checked + ul{
								max-height:600px;
							}
				}
			}
	}
}