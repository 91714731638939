// Header
.inner__cont{
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
	padding: 0px 0px 20px 0px;
	width: 100%;
	h2{
		font-size: 29px;
		font-weight: 300;
		color: white;
		line-height: 29px;
		margin-bottom: 0px;
		display: inline-block;
		position: relative;
		margin-top: 23px;
		i{
			display: inline-block;
			width: 42px;
			height: 42px;
			.border-radius(100%);
			text-align: center;
			vertical-align: middle;
			background-color: #5395ac;
			padding-top: 3px;
			margin-top: 2px;
			&:before{
				vertical-align: middle;
			}
		}
		span{
			display: inline-block;
			vertical-align: middle;
		}
	}

	&:after{
		content: "";
		display: inline-block;
		width: 100%;
		border-bottom: 1px solid #5898b0;
	}

	.right-toolbar{
		float: right;
		width: 55%;
		padding-top: 31px;
		ul{
			margin: 0;
			padding: 0;
			list-style: none;
			height: 100%;
			text-align: right;
			li{
				display: inline-block;
				vertical-align: bottom;
				margin-right: 10px;
				&:last-child{
					margin-right: 0;
				}

				>label{
					    display:block;
					    text-align: left;
					    width: 100%;
    					color: #444;
    					position: relative;
    					box-sizing: border-box;
    					padding-left: 10px;
    					font-weight: 700;
    					font-size: 13px;
				}

				fieldset{
					border: none;
					position: relative;
					i{
						display: inline-block;
						position:absolute;
						width: 32px;
						height: 32px;
						.opacity(1);
						font-size: 32px;
						right: -8px;
    					top: 2px;
    					color: #454545;
					}
				}

				// Mixed Input Fields
				[type="text"],
				[type="email"],
				[type="password"],
				[type="tel"],
				[type="number"],
				[type="date"] {
				  width: 100%;
				  max-width: 500px;
				  min-width: 230px;
				  height: 36px;
				  padding: 10px;
				  background-color: @base-input-background-color;
				  border: 1px solid @base-input-border-color;
				  border: none;
				  -webkit-appearance: none;
				  -moz-appearance: textfield;
				  border-radius: 0;
				  .border-radius(2px);
				  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
				  &:focus {
				    background-color: @base-input-background-focus-color;
				    border-color: @base-input-border-focus-color;
				    outline: 0;
				  }
				}
			}
		}
	}
}