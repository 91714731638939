/* Style For Suggestions */
    
/*
 For creating side border like this
 | item 1   |
 | item 2   |
 */

.suggestions{
	margin-top: 17px;
    margin-left: 8px;
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
    .border-radius(0 0 4px 4px);
    z-index: 1;
}

.suggestions .suggest_item{
	padding-bottom: 2px;
	padding-top: 2px;
	background-color:#FFF;
	box-sizing: border-box;
    padding-left: 6px;
    font-family: @base-font-family;
}

/*
 For creating top border like this
 ------------
   item 1
   ...
 */
.suggestions .suggest_item.first{
	
}

/*
 For creating bottom border like this
   ...
   item 2
  ------------
 */
.suggestions .suggest_item.last{
	
}

/*
 For coloring the selected item
 */
.suggestions .suggest_item.selected, .suggestions .suggest_item.selected .description{
	background-color:#999999;
	color:#FFFFFF;
	cursor:pointer;
}

/*
 Image thumbnail
 */
.suggestions .suggest_item .thumbnail{
	background-color: transparent;
	background-position: top center;
	background-repeat: no-repeat;
	margin: 1px 2px 1px 2px;
	float: left;
	width: 50px;
	height: 50px;
}

/*
 Description
 */
.suggestions .suggest_item .description{
	font-style: italic;
	font-size: 11px;
	color: #777;
}