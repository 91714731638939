.side-container{
	display: inline-block;
	vertical-align: top;
}

.gral-grafico{
	width: 580px;
}
.wgt-all-grafico{
	width: 550px;
	height: 400px;
	float: left;
	padding: 10px;
	box-sizing: border-box;
	>header{
		width: 100%;
		display: inline-block;
		margin: 0;
		padding: 0;
		h3{
			font-weight: 400;
			strong{
				font-weight: 600;
			}
		}
		ul{
			width: 100%;
			.display(flex);
			padding: 0;
			margin: 0;
			list-style: none;
			li{
				box-sizing: border-box;
				padding: 5px;
				text-align: center;
				p{ font-size: 28px; text-align: center; margin: 0; padding: 0;}
				span{
					font-size: 11px;
					text-align: center;
				}
				&:empty{
					margin: 0px 5px;
					position: relative;
					&:before{
						content: "";
						display: inline-block;
						border-right: 1px solid silver;
						position: absolute;
						left: 4px;
						top: 0px;
						height: 100%;
					}
				}
			}
		}

		.bar-percent{
			margin-top: 20px;
			width: 60%;
			height: 26px;
			display: inline-block;
			position: relative;
			.inner-bar{
				background-color: #f44336;
				width: 100%;
				height: 26px;
				text-align: left;
				white-space: nowrap;
				.border-radius(4px);
				font-weight: 600;
				i{
					left: 5px;
					top: 1px;
					font-style: normal;
					position:absolute;
					font-size: 12px;
					vertical-align: middle;
					display: inline-block;
					color: white;
				}

				.progress{
					.trans();
					.border-radius(4px 0 0 4px);
					display: inline-block;
					width: 50%;
					height: 100%;
					vertical-align: top;
					background-color: #8bc34a;
					text-align: center;
				}
				.progress + i{
					position: relative;
					color: white;
					&.dark{
						left: 20px;
						color: #f44336;
					}
					&.dark-closer{
						left: 10px;
						color: #f44336;
					}
				}
			}
		}
	}

	.content-grafico{
		margin-top: 20px;
	}
}

.list-panel-fixed{
	.border-radius(8px);
	width: 33%;
	position: fixed;
	right: 1%;
	top:2vh;
	height: 96vh;
	text-align: right;
	&:before{
		content: "";
		display: inline-block;
		position: absolute;left: 0px;
		top: 0px;
		width: 3%;
		height: 100%;
		background-color: #29B6F6;
		.border-radius(8px 0 0 8px);
	}
	.inner-list-panel{
		text-align: left;
		.border-radius(0px 8px 8px 0px);
		width: 97%;
		display: inline-block;
		height: 100%;
		background-color: #F4F4F4;
		header{
			width: 100%;
			background-color: #DDDDDD;
			padding: 5px 10px;
			box-sizing: border-box;
			.border-radius(0 8px 0 0);
			h3{
				white-space: nowrap;
				&:before{
					content: "Envíos anteriores:";
					font-size: 16px;
					font-weight: 400;
					display: inline-block;
					padding-right: 5px;
				}
				font-size: 18px;
				font-weight: 600;
			}
		}
		ul{
			margin: 0px;
			padding: 0px;
			display: inline-block;
			width: 100%;
			height: 92%;
			overflow: auto;
			list-style: none;
			li{
				.border-radius(4px 4px 0 0);
				border-right: 1px solid rgba(192, 192, 192, 0);
				border-top: 1px solid rgba(192, 192, 192, 0);
				position: relative;
				width: 95%;
				.display(flex);
				.align-items(center);
				.justify-content(space-around);
				margin: 10px;
				margin-bottom: 35px;
				.trans();
				cursor: pointer;
				&:before{
				    width: 18px;
				    left: 0px;
				    top: -1px;
				    height: 86px;
					content: "";
					display: inline-block;
					z-index: 10;
					background-color: white;
					position: absolute;
					.border-radius(4px 0 0 4px);
					.transform(translateX(0px));
					.opacity(0);
					.trans();
					border-top: 1px solid rgba(192, 192, 192, 0);
					border-bottom: 1px solid rgba(192, 192, 192, 0);
				}

				&:after{
					.trans();
					content: "";
					display: block;
					position: absolute;
					z-index: 10;
					left: 0px;
					bottom: -11px;
					right: -1px;
					height: 8px;
					.border-radius(0px 0px 4px 4px);
					border-right: 1px solid rgba(192, 192, 192, 0);
					border-bottom: 1px solid rgba(192, 192, 192, 0);
					box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
					background-color: #f4f4f4;
				}

				&.active{
					border-right: 1px solid rgba(192, 192, 192, 0.4);
					border-top: 1px solid rgba(192, 192, 192, 0.4);
					background-color: white;
					&:before{
						.opacity(1);
						.transform(translateX(-16px));
						border-top: 1px solid rgba(192, 192, 192, 0.4);
						border-bottom: 1px solid rgba(192, 192, 192, 0.4);
					}
					&:after{
						box-shadow: 0px 3px 2px rgba(0, 0, 0, 0);
						background-color: white;
						bottom: -8px;
						border-right: 1px solid rgba(192, 192, 192, 0.4);
					    border-bottom: 1px solid rgba(192, 192, 192, 0.4);
					}
					.mini-calendar{
						.transform(translateY(-4px));
					}
				}


				.mini-calendar{
					background-color: white;
					position: relative;
					z-index: 20;
					max-width: 50px;
					display: inline-block;
					.border-radius(4px);
					text-align: center;
					font-size: 28px;
					font-weight: 600;
					vertical-align: middle;
					.trans();
					.transform(translateY(0px));
					span{
						vertical-align: top;
						display: inline-block;
						width: 100%;
						text-align: center;
						font-size: 14px;
						font-weight: 400;
						background-color: #FCA459;
						.border-radius(4px 4px 0 0);
						color: white;
						margin-bottom: 9px;
					}
					p{
						display: inline-block;
						width: 100%;
						text-align: center;
						font-size: 12px;
						font-weight: 400;
						margin: 0;
						padding: 0;
						margin-top: 2px;
						vertical-align: top;
					}
				}

				.list-total-enviados{
					position: relative;
					z-index: 20;
					border-right: 1px solid silver;
					display: inline-block;
					max-width: 120px;
					text-align: center;
					vertical-align: middle;
					padding-bottom: 10px;
					span{
						width: 100%;
						font-size: 14px;
					}
					font-size: 32px;

				}
				.list-detalle-enviados{
					position: relative;
					z-index: 20;
					border-right: 1px solid silver;
					display: inline-block;
					max-width: 120px;
					text-align: center;
					vertical-align: middle;
					padding-bottom: 0px;
					span{
						display: inline-block;
						text-align: right;
						white-space: nowrap;
						i{
							float: left;
							display: inline-block;
							vertical-align: middle;
							width: 24px;
							text-align: center;
							svg{
								margin-top: 2px;
								display: inline-block;
								vertical-align: middle;
							}
						}
						width: 65%;
						font-size: 18px;
					}
					font-size: 32px;

				}
				.list-actions-enviados{
					position: relative;
					z-index: 20;
					display: inline-block;
					max-width: 120px;
					text-align: center;
					vertical-align: middle;
					padding-bottom: 0px;
				}

				&:last-child{
					.calc(margin-bottom, ~'92vh - 133px');
				}
			}
		}
	}
}