// ==========================================================================
// Base – Code
// ==========================================================================
code, kbd, pre, samp {
  .font-size(@base-code-font-size);
  .line-height(@base-code-line-height);
  word-wrap: break-word;
  font-family: @base-code-font-family;
  color: @base-code-color;
  background-color: @base-code-background-color;
  font-weight: normal;
  padding: 0;
  white-space: pre-wrap;
}
pre {
  padding: 0px;
  overflow: auto;
  min-height: 160px;
  max-height: 550px;
  margin: 0px;
  width: 90%;
}
code{
  display: inline-block !important;
  width: 100%;
  vertical-align: top;
}