article.e-card{
	.border-radius(4px);
	border: 1px solid #e0e0e0;
	width: 100%;
	max-width: 550px;
	min-width: 66px;
	height: auto;
	background-color: #FFF;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
	padding: 15px;
	position:relative;
	margin: 20px;
	margin-left: 0px;
	white-space: nowrap;
	overflow: hidden;
	.transform(translateY(0px));
	.trans();


	figure{
		vertical-align: middle;
		display: inline-block;
		width: 64px;
		height: 64px;
		padding: 0;
		margin: 0;
		img{
			overflow: hidden;
			.border-radius(50%);
			width: 100%;
		}
	}
	figcaption{
		margin-left: 10px;
		vertical-align: middle;
		display: inline-block;
	}
	button.settings{
		font-size: 22px;
		border: none;
		background-color:transparent;
		position:absolute;
		right: 10px;
		top: 10px;
	}

	&.closed{
		.transform(translateY(10px));
		.border-radius(50px);
		width: 1%;
	    white-space: nowrap;
	    min-width: 66px;
	    padding: 0px;
	}

}