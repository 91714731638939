header.fixed-header{
	position: fixed;
	top: 0px;
	width: 100%;
	background-color: #ffffff;
	border-top:1px solid #b6b4b6;
	height: 55px;
	z-index: 100;
	box-shadow: 1px 0px 6px 1px rgba(0,0,0,0.3);
	aside.side-menu{
		vertical-align: top;
		width:@base-sidebar-width;
		display: inline-block;
		height: 100%;
		background-color: #ededed;
		button.button{
			vertical-align: middle;
			margin: 6px;
			display: inline-block;
			.border-radius(0px);
			border: none;
			background-color: #ededed;
			color: #8f8f8f;
			font-size: 18px;
			width: 43px;
    		padding: 10px;
			&:hover{
				background-color: #fff;
			}
			i{
				color: #8f8f8f;
			}
		}
		h1{
			vertical-align: middle;
			display: inline-block;
			line-height: 10px;
			color: #8f8f8f;
			font-size: 10px;
			margin-bottom: 0px;
		}
	}

	svg{
		width: 200px;
		height: 50px;
		vertical-align: middle;
		display: inline-block;
	}
}