.download-range{
	opacity: 1;
	.transform(scale(1));
	&.hide{
		.transform(scale(0));
		opacity: 0;
	}
}

.expand-all{
	.fa-compress{
		display: none;
	}
	&.contract-icon{
		.fa-expand{
			display: none;
		}
		.fa-compress{
			display: inline-block;
		}
	}
	span{
		&.fa-expand{ &:before{ content: "";}}
		&.fa-compress{ &:before{ content: "";}}
	}
}

.wgt_content_graficos{
	width: 100%;
	.display(flex);
	.align-items(center);
	.justify-content(flex-start);
	flex-wrap: wrap;
	.linebreak{
		width: 100%;
		display: inline-block;
	}
	.wgt_grafico{
		overflow: hidden;
		margin-bottom: 25px;
		position: relative;
		margin-left: 25px;
		min-width: 430px;
		max-width: 830px;
		box-sizing: border-box;
		padding: 12px;
		.border-radius(8px);
		vertical-align: top;
		display: inline-block;
		.trans();
		min-height: 360px;
		pre{
			color:rgba(255, 255, 255, 0);
			background-image: url(../../img/placeholder.svg);
			background-color: white;
			.border-radius(4px);
			width: 410px;
			max-width: 410px;
			max-height: 360px;
			height: 360px;
			overflow: hidden;
			position: absolute;
			z-index: 1000;
			top: 11px;
			&.no-data{
				&:before{
					font-family: @base-font-family;
					position: absolute;
					left: 0px;
					top: 0px;
					right: 0px;
					bottom: 0px;
					background-color: rgba(255, 255, 255, .7);
					content: "No hay datos asociados al filtro";
					display: inline-block;
					color: #333;
					text-align: center;
					box-sizing: border-box;
					padding-top: 10px;
				}
			}
		}
		&.expand{
			min-width: 894px;
			.abs-right{
				.transform(translateX(0%));
			}
			.inner-expanded-grafico{
				.transform(translateX(0%));
			}
			.inner-grafico{
				.transform(translateX(-5%));
				&:after{
					.opacity(1);
				}
			}
		}
		&:last-child{
			 .align-self(flex-start);
		}

		p{
			font-size: 12px;
			padding-top: 15px;
			margin: 0;
			color: #a7a7a7;
		}
		ul{
			max-width: 410px;
			margin: 0;
			padding: 0;
				.display(flex);
				.align-items(center);
				.justify-content(flex-start);

			&.abs-right{
				position: absolute;
				right: 0px;
				top: 82px;
    			min-width: 482px;
    			.trans();
    			.transform(translateX(100%));
			}
			li{
				display: inline-block;
				list-style: none;
				font-size: 34px;
				box-sizing: border-box;
				padding: 10px;
				vertical-align: middle;
				line-height: 24px;
				&:empty{
					width: 0px;
					border:1px solid silver;
					vertical-align: middle;
					height: 100%;
					min-height: 54px;
					padding: 0;
					 align-self:stretch;
				}
				span{
					font-size: 11px;
					display:block;
					width: 100%;
					color: #a7a7a7;
				}
			}
		}
		.inner-grafico{
			width: 100%;
			display: inline-block;
			vertical-align:top;
			max-width: 410px;
			.trans();
			.transform(translateX(0%));
			position: relative;
			&:after{
				position: absolute;
				content: "";
				display: block;
				.opacity(0);
			    right: -12px;
			    top: 10%;
			    height: 70%;
				border-left: 1px solid silver;
				.trans();
			}
		}
		.inner-expanded-grafico{
			position: absolute;
			right: 0px;
			top: 150px;
			.trans();
			.transform(translateX(100%));
			width: 100%;
			max-width: 480px;
		}

		.expanded-toolbar{
			position: absolute;
			right: 5px;
			top: 5px;
			list-style: none;
			li{
			}
		}

		button{
			position: absolute;
			right: 20px;
			bottom: 20px;
			i{
				display: inline-block;
				width: 22px;
				height: 22px;
				vertical-align: middle;
				.icon{
					width: 22px;
					height: 22px;
				}
			}
		}
	}
}